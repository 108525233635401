var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideInvitations,
          expression: "hideInvitations",
        },
      ],
      staticClass: "icon invitations with-list notify-pop",
      class: {
        "has-notify": _vm.pending.length > 0,
        "list-visible": _vm.visible,
      },
    },
    [
      _c(
        "a",
        {
          directives: [{ name: "tooltip", rawName: "v-tooltip" }],
          staticClass: "tooltip-bottom",
          attrs: { alt: _vm.$t.get("common.invitations") },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggleInvitations.apply(null, arguments)
            },
          },
        },
        [
          _c("i", { staticClass: "ict-invitations" }),
          _vm._v(" "),
          _c("div", { staticClass: "badge" }, [
            _vm._v(_vm._s(_vm.pending.length)),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.popVisible === true && _vm.popClosed === false
        ? _c("div", { staticClass: "pop" }, [
            _c("div", { staticClass: "text" }, [
              _c("div", { staticClass: "badge" }, [
                _vm._v(_vm._s(_vm.pending.length)),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    this.$t.get("common.header-invitations-pop", {
                      count: _vm.pending.length,
                    })
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "close", on: { click: _vm.closePop } }, [
              _c("i", { staticClass: "ict-close" }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list" },
        [
          _c("div", { staticClass: "header flex" }, [
            _c("div", { staticClass: "lg:w-6/12 columns" }, [
              _vm._v(_vm._s(_vm.$t.get("common.invitations"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "lg:w-6/12 text-right columns" }, [
              _c("a", { attrs: { href: "/invitations" } }, [
                _vm._v(_vm._s(_vm.$t.get("common.show-all-btn"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-scrollbar",
            [
              _vm._l(_vm.invitations, function (invitation) {
                return _c(
                  "a",
                  {
                    key: invitation.invitation_id,
                    staticClass: "item",
                    attrs: { href: "/invitation/" + invitation.invitation_id },
                  },
                  [
                    _c("div", {
                      staticClass: "avatar",
                      style: {
                        backgroundImage:
                          "url(" + invitation.created_by.avatar_url + ")",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "info" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(invitation.created_by.name) +
                            "\n            " +
                            _vm._s(invitation.created_by.surname) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "date" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$dateTimeFormat.formatDate(
                                invitation.created_at
                              )
                            ) +
                            "\n            " +
                            _vm._s(
                              _vm.$dateTimeFormat.formatTime(
                                invitation.created_at
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      invitation.creator_group && invitation.creator_group.name
                        ? _c("div", { staticClass: "date" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t.get("common.from")) +
                                ": " +
                                _vm._s(invitation.creator_group.name) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      invitation.subject
                        ? _c("div", { staticClass: "date" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t.get("common.case")) +
                                ": " +
                                _vm._s(_vm.$t.get(invitation.subject)) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.isPending(invitation)
                      ? _c("div", { staticClass: "new" }, [
                          _vm._v(_vm._s(_vm.$t.get("common.new"))),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.invitations.length === 0,
                      expression: "invitations.length === 0",
                    },
                  ],
                  staticClass: "item",
                },
                [
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t.get("common.you-do-not-have-any-invitations")
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "date" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t.get(
                              "common.you-will-find-here-the-list-of-invitations"
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }