//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createNamespacedHelpers } from "vuex"
import RxTime from "../../plugins/RxTime"
import Modal from "../../plugins/modal/modal"
import WillBeLate from "../appointment/WillBeLate.vue"

const { mapGetters } = createNamespacedHelpers("appointment")
import { Constants } from "../../models/Constants"

export default {
  mixins: [RxTime.mixin()],
  data() {
    return {
      Constants: Constants,
      appointments: [],
      visible: false,
      processing: false,
      tmp: null,
      lateDelay: 5,
      joinCallModal: {},
    }
  },
  sockets: {
    appointment: async function (appointment) {
      if (typeof appointment.timeline !== typeof undefined) {
        return
      }

      if (
        [
          Constants.Appointment.STATUS_PAID,
          Constants.Appointment.STATUS_RESCHEDULED,
          Constants.Appointment.STATUS_IN_PROGRESS,
        ].indexOf(appointment.status) > -1 &&
        appointment.consultation_type === Constants.Appointment.CONSULTATION_CALL_OPINION
      ) {
        let appointmentIndex = this.appointments.findIndex((item) => {
          return item.appointment_id === appointment.appointment_id
        })
        if (appointmentIndex === -1) {
          this.appointments.push(appointment)
        } else {
          this.appointments[appointmentIndex] = appointment
        }
      }

      if (
        document.location.pathname.startsWith("/appointment") === false &&
        appointment.status === Constants.Appointment.STATUS_IN_PROGRESS &&
        this.user.user_id !== appointment.assigned_id &&
        typeof appointment.participants !== void 0
      ) {
        const participant = appointment.participants.find((p) => p.user_id === this.user.user_id)
        if (participant !== void 0 && participant.on_the_call) {
          this.joinCallModal[appointment.id] = await Modal.confirm(this)
            .title(this.$t.get("common.call-in-progress-modal.title"))
            .content(
              this.$t.get("common.call-in-progress-modal.text-with", {
                name: appointment.assigned.display_name,
              })
            )
            .positive(this.$t.get("common.call-in-progress-modal.positive"), function () {
              window.location.href = "/appointment/" + appointment.appointment_id + "/call"
            })
            .negative(this.$t.get("common.call-in-progress-modal.negative"))
            .create()

          this.joinCallModal[appointment.id].open()
        }
      } else if (
        appointment.status === Constants.Appointment.STATUS_CALL_ENDED &&
        this.joinCallModal[appointment.id] !== void 0
      ) {
        this.joinCallModal[appointment.id].close()
      }
    },
  },
  mounted() {
    this.getUpcomingCalls()
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    ...mapGetters(["getContextRecipientOfAppointment"]),
    appointmentsSorted() {
      return this.appointments.sort(
        (a, b) => new Date(b.term.start_date) - new Date(a.term.start_date)
      )
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$bridge.emit("hideInvitationsPop")
        } else {
          this.$bridge.emit("showInvitationsPop")
        }
      },
    },
  },
  methods: {
    getUpcomingCalls() {
      this.$http
        .get("/ajax/appointments/upcoming")
        .then((response) => {
          this.appointments = response.data.data
        })
        .catch((error) => {})
    },
    toggleTodayCalls() {
      this.visible = !this.visible
    },
    hideTodayCalls() {
      this.visible = false
    },
    cancelCall(appointment) {
      Modal.confirm(this)
        .title(this.$t.get("appointment.cancel-the-call"))
        .content(this.$t.get("appointment.cancel-the-call-prompt"))
        .positive(this.$t.get("common.yes"), function (modal) {
          return this.$http
            .post("/ajax/appointment/" + appointment.appointment_id + "/cancel")
            .then(
              (response) => {
                this.$bridge.emit(
                  "addToast",
                  this.$t.get("appointment.the-call-has-been-canceled"),
                  "success"
                )
                return true
              },
              (response) => {
                this.$bridge.emit(
                  "addToast",
                  this.$t.get("common.something-went-wrong-please-try-again"),
                  "error"
                )
                return false
              }
            )
        })
        .negative(this.$t.get("common.no"))
        .show()
    },
    willBeLate(appointment) {
      Modal.create(this)
        .title(this.$t.get("common.i-will-be-late"))
        .component(WillBeLate)
        .props({ appointment })
        .show()
    },
  },
}
