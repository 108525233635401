var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "user !hidden md:!inline-block",
      class: { visible: _vm.isVisible },
      on: { mouseenter: _vm.mouseEnter, mouseleave: _vm.mouseLeave },
    },
    [
      _c("div", { staticClass: "details" }, [
        _c("div", {
          staticClass: "avatar",
          style: { backgroundImage: "url(" + _vm.user.avatar_url + ")" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.user.name)),
          _c("br"),
          _vm._v(_vm._s(_vm.user.surname)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "arrow" }, [
          _vm.isVisible == false
            ? _c("i", { staticClass: "ict-angle-down" })
            : _vm._e(),
          _vm._v(" "),
          _vm.isVisible == true
            ? _c("i", { staticClass: "ict-angle-up" })
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.isVisible
        ? _c("div", { staticClass: "dropdown" }, [
            _c("ul", [
              _c("li", [
                _c("a", { attrs: { href: "/invitations" } }, [
                  _c("i", { staticClass: "ict-invitations" }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t.get("common.invitations"))),
                  ]),
                  _vm._v(" "),
                  _vm.getPendingInvitationsCount > 0
                    ? _c("div", { staticClass: "badge" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.getPendingInvitationsCount) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _vm.$store.state.payments.payments
                ? _c("li", [
                    _c("a", { attrs: { href: "/payments" } }, [
                      _c("i", { staticClass: "ict-payments" }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t.get("common.payments"))),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("li", [
                _c("a", { on: { click: _vm.openCloudManager } }, [
                  _c("i", { staticClass: "ict-data-cloud" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t.get("common.data-cloud")))]),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("a", { attrs: { href: "https://help.trustedoctor.com/" } }, [
                  _c("i", { staticClass: "ict-help" }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t.get("common.help-center"))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("a", { attrs: { href: "/account/settings" } }, [
                  _c("i", { staticClass: "ict-settings" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t.get("common.settings")))]),
                ]),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "divider" }),
              _vm._v(" "),
              _c("li", [
                _c("a", { attrs: { href: "/logout" } }, [
                  _c("i", { staticClass: "ict-logout-alt" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t.get("common.logout")))]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }