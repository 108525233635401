import Vue from "../vue.js"

import HeaderCalls from "../components/header/HeaderCalls.vue"
import HeaderReports from "../components/header/HeaderReports.vue"
import HeaderNotifications from "../components/header/HeaderNotifications.vue"
import HeaderInvitations from "../components/header/HeaderInvitations.vue"
import HeaderUser from "../components/header/HeaderUser.vue"
import store from "../stores/store"
import { createNamespacedHelpers } from "vuex"
import Modal from "../plugins/modal/modal"
import Signin from "../components/common/Signin.vue"
import ResetPassword from "../components/common/ResetPassword.vue"
import TimezoneChoice from "../components/common/TimezoneChoice.vue"
import { Permissions } from "../models/Permissions"

import ToastManager from "../components/header/ToastManager.vue"
import Customize from "../plugins/Customize.js"
import InstallMobileApp from "../components/common/InstallMobileApp.vue"
import AccountRemovalBar from "../components/common/AccountRemovalBar.vue"

const { mapGetters: mapUserGetters } = createNamespacedHelpers("user")

if (document.querySelectorAll("#header-app").length > 0) {
  new Vue({
    el: "#header-app",
    mixins: [new Customize()],
    store,
    components: {
      "header-calls": HeaderCalls,
      "header-reports": HeaderReports,
      "header-notifications": HeaderNotifications,
      "header-invitations": HeaderInvitations,
      "header-user": HeaderUser,
      "timezone-choice": TimezoneChoice,
      "account-removal-bar": AccountRemovalBar,
    },
    data() {
      return {
        permissions: Permissions,
        menuActive: false,
        isAppBannerVisible: this.$cookie.get("mobileAppBanner") === null,
        isGuestMenuActive: false,
      }
    },
    computed: {
      isMobile() {
        return window.isMobile()
      },
      ...mapUserGetters(["belongsToAnyGroup", "getGroups", "canManageGroup", "hasGroupPermission"]),
      groups() {
        return this.getGroups.slice(0, 5)
      },
      user() {
        return this.$store.state.user
      },
      timeZone() {
        return this.$store.state.user.time_zone
      },
      isIOS() {
        return (
          bowser.ios === true ||
          // check for iPad 13+ (user agent looks like MacOS Desktop)
          (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
        )
      },
      isAndroid() {
        return bowser.android === true
      },
      isRemovalRequest() {
        return this.$store.state.user?.removal_request_at != null
      },
    },
    watch: {
      isAppBannerVisible: {
        immediate: true,
        handler() {
          let isBannerAvailable = document.querySelector(".app-banner") !== null
          if ((this.isIOS || this.isAndroid) && this.isAppBannerVisible && isBannerAvailable) {
            document.body.style.overflow = "hidden"
          }
        },
      },
    },
    mounted() {
      if (
        this.user &&
        window.__TIMEZONE_CHOICE !== undefined &&
        window.__TIMEZONE_CHOICE !== false
      ) {
        this.timezoneChoice(
          this.$t.get("common.timezone-change-detected"),
          window.__TIMEZONE_CHOICE
        )
      }

      if (window.__RESET_TOKEN !== null && window.__RESET_TOKEN !== "") {
        this.resetPassword()
      }

      if (window.__SIGNIN_REQUIRED === true) {
        this.signIn()
      }

      var ComponentClass = Vue.extend(ToastManager)
      var toastManagerInstance = new ComponentClass()
      toastManagerInstance.$mount() // pass nothing
      document.body.appendChild(toastManagerInstance.$el)
    },
    bridges: {
      menuVisibiltyChanged(state) {
        this.menuActive = state
      },
    },
    methods: {
      toggleGuestMenu() {
        this.isGuestMenuActive = !this.isGuestMenuActive
      },
      signIn() {
        if (this.isIOS || this.isAndroid) {
          Modal.create(this)
            .title(this.$t.get("common.mobile-app"))
            .component(InstallMobileApp)
            .show()
        } else {
          Modal.create(this).title(this.$t.get("auth.sign-in")).component(Signin).show()
        }
      },
      resetPassword() {
        Modal.create(this)
          .title(this.$t.get("auth.reset-password"))
          .component(ResetPassword)
          .props({
            token: window.__RESET_TOKEN,
          })
          .show()
      },
      timezoneChoice(description, selected) {
        if (description === undefined) {
          description = this.$t.get("common.timezone-change-detected")
        }

        if (selected === undefined) {
          selected = this.timeZone
        }

        Modal.create(this)
          .title(this.$t.get("common.timezone"))
          .component(TimezoneChoice)
          .props({
            description: description,
            timeZone: { label: selected, value: selected },
          })
          .show()
      },
    },
  })
}
