var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideTodayCalls,
          expression: "hideTodayCalls",
        },
      ],
      staticClass: "icon today-calls with-list",
      class: {
        "has-notify": _vm.appointments.length > 0,
        "list-visible": _vm.visible,
      },
    },
    [
      _c(
        "a",
        {
          directives: [{ name: "tooltip", rawName: "v-tooltip" }],
          staticClass: "tooltip-bottom",
          attrs: { href: "#", alt: _vm.$t.get("common.todays-calls") },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggleTodayCalls.apply(null, arguments)
            },
          },
        },
        [
          _c("i", { staticClass: "ict-calendar-alt" }),
          _vm._v(" "),
          _c("div", { staticClass: "badge" }, [
            _vm._v(_vm._s(_vm.appointments.length)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list" },
        [
          _c("div", { staticClass: "header" }, [
            _vm._v(_vm._s(_vm.$t.get("common.upcoming-calls"))),
          ]),
          _vm._v(" "),
          _c(
            "v-scrollbar",
            [
              _vm._l(_vm.appointmentsSorted, function (appointment) {
                return _c("div", { key: appointment.id, staticClass: "call" }, [
                  _c("div", { staticClass: "flex small-collapse" }, [
                    _c(
                      "div",
                      { staticClass: "w-4/12 columns time" },
                      [
                        appointment.status ===
                        _vm.Constants.Appointment.STATUS_IN_PROGRESS
                          ? [
                              _vm._v(
                                _vm._s(_vm.$t.get("common.call-in-progress"))
                              ),
                            ]
                          : _vm
                              .$moment(_vm.now)
                              .isBefore(appointment.term.start_date)
                          ? [
                              _vm._v(
                                _vm._s(
                                  _vm.$momentUtils.timeLeft(
                                    appointment.term.start_date,
                                    60
                                  )
                                )
                              ),
                            ]
                          : [
                              _vm._v(
                                _vm._s(_vm.$t.get("common.waiting-for-call"))
                              ),
                            ],
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-7/12 columns" }, [
                      _c("div", {
                        staticClass: "avatar",
                        style: {
                          backgroundImage:
                            "url(" +
                            _vm.getContextRecipientOfAppointment(
                              appointment,
                              _vm.user.user_id
                            ).avatar_url +
                            ")",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "with" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              appointment.requester_id !== _vm.user.user_id &&
                                appointment.assigned_id !== _vm.user.user_id
                                ? appointment.assigned.name +
                                    " " +
                                    appointment.assigned.surname
                                : ""
                            ) +
                            "\n              " +
                            _vm._s(_vm.$t.get("common.with")) +
                            "\n              " +
                            _vm._s(
                              _vm.getContextRecipientOfAppointment(
                                appointment,
                                _vm.user.user_id
                              ).name
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-1/12 columns buttons" }, [
                      _c(
                        "a",
                        {
                          directives: [
                            { name: "tooltip", rawName: "v-tooltip" },
                          ],
                          staticClass: "button accent",
                          attrs: {
                            href: "/appointment/" + appointment.appointment_id,
                            alt: "Go to appointment",
                          },
                        },
                        [_c("i", { staticClass: "ict-appointment" })]
                      ),
                    ]),
                  ]),
                ])
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.appointments.length == 0,
                      expression: "appointments.length == 0",
                    },
                  ],
                  staticClass: "item",
                },
                [
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t.get("common.you-do-not-have-any-calls-today")
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "date" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t.get(
                              "common.you-will-find-here-the-list-of-all-todays-pending-calls"
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }