//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Favico from "favico.js"
import { showBrowserNotification } from "../../../utils"

export default {
  data() {
    return {
      favico: "",
      notifications: [],
      unreaded: 0,
      visible: false,
    }
  },
  sockets: {
    notification(notification) {
      notification.data = notification
      this.notifications.unshift(notification)
      this.countUnread()
      if (this.$pageVisible === false) {
        showBrowserNotification(
          "Trustedoctor.com",
          notification.data.label,
          "/notifications/" + notification.id,
          notification.image
        )
      }
    },
  },
  mounted() {
    this.downloadNotifications()

    if ("Notification" in window && Notification.permission !== "granted") {
      Notification.requestPermission()
    }

    this.favico = new Favico({
      animation: "none",
    })
  },
  watch: {
    visible: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$bridge.emit("hideInvitationsPop")
        } else {
          this.$bridge.emit("showInvitationsPop")
        }
      },
    },
  },
  methods: {
    countUnread() {
      const unread = this.notifications.filter((o) => o.read_at == null)
      this.unreaded = unread.length
      if (typeof this.favico.badge == "function") {
        this.favico.badge(this.unreaded)
      }
    },
    downloadNotifications() {
      this.$http.get("/ajax/notifications").then(
        (response) => {
          if (response.data.data !== void 0) {
            let notifications = response.data.data.data
            if (typeof notifications !== typeof undefined) {
              this.notifications = Object.keys(notifications).map((k) => notifications[k])
              this.countUnread()
            }
          }
        },
        (error) => {
          this.$bridge.emit("addToast", error.response.data.message, "error")
        }
      )
    },
    toggleNotifications() {
      if (!this.visible) {
        const ids = [].concat(
          ...this.notifications.filter((o) => o.read_at == null).map((o) => o.id)
        )

        this.notifications.forEach((value) => (value.read_at = this.$moment()))

        this.unreaded = 0
        this.favico.badge(this.unreaded)
        this.$http.post("/ajax/notifications/read", { ids: ids }).then(
          (response) => {},
          (error) => {
            this.$bridge.emit(
              "addToast",
              this.$t.get("common.something-went-wrong-please-try-again"),
              "error"
            )
          }
        )
      }
      this.visible = !this.visible
    },
    hideNotifications() {
      this.visible = false
    },
  },
}
