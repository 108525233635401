//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from "../../plugins/modal/modal"

import { createNamespacedHelpers } from "vuex"
import { Permissions } from "../../models/Permissions"

const { mapGetters: mapUserGetters } = createNamespacedHelpers("user")

export default {
  data() {
    return {
      isVisible: false,
      permissions: Permissions,
    }
  },
  computed: {
    ...mapUserGetters(["hasGroupPermission", "getPendingInvitationsCount", "getGroups"]),
    user() {
      return this.$store.state.user
    },
    groups() {
      return this.getGroups.slice(0, 5)
    },
  },
  methods: {
    mouseEnter() {
      this.isVisible = true
    },
    mouseLeave() {
      this.isVisible = false
    },
    openCloudManager() {
      Modal.create(this)
        .title(this.$t.get("cloud-manager.my-medical-cloud"))
        .component(() => import("../cloud-manager/CloudManager.vue"))
        .classes(["modal-cloud-manager"])
        .closeable(false)
        .props({
          showActions: true,
          exploreMode: true,
          jwt: window.USER_JWT,
        })
        .show()
    },
  },
}
