//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Constants } from "../../models/Constants"

export default {
  data() {
    return {
      popVisible: false,
      popClosed: false,
      invitations: [],
      visible: false,
    }
  },
  bridges: {
    hideInvitationsPop() {
      this.hidePop()
    },
    showInvitationsPop() {
      this.showPop()
    },
  },
  sockets: {
    pending_invitations_count() {
      this.fetch()
    },
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    pending() {
      if (this.invitations !== undefined) {
        return this.invitations.filter((invitation) => this.isPending(invitation))
      }
      return []
    },
  },
  mounted() {
    this.fetch()
  },
  watch: {
    pending: {
      immediate: true,
      handler(value) {
        this.bindPop()
      },
    },
    visible: {
      immediate: true,
      handler(value) {
        if (value) {
          this.hidePop()
        } else {
          this.showPop()
        }
      },
    },
  },
  methods: {
    fetch() {
      this.$http
        .post("/ajax/invitations")
        .then((response) => {
          if (typeof response.data.data.data === typeof undefined) {
            this.invitations = []
          } else {
            this.invitations = response.data.data.data
          }

          this.$forceUpdate()
        })
        .catch((_) => {
          // Silent catch
        })
    },
    toggleInvitations() {
      this.visible = !this.visible
    },
    hideInvitations() {
      this.visible = false
    },
    bindPop() {
      if (this.pending.length > 0) {
        this.showPop()
      } else {
        this.hidePop()
      }
    },
    showPop() {
      if (this.pending.length > 0 && window.__CURRENT_ROUTE_NAME.indexOf("invitation") === -1) {
        this.popVisible = true
      }
    },
    hidePop() {
      this.popVisible = false
    },
    closePop() {
      this.popClosed = true
    },
    isPending(invitation) {
      return (
        invitation.status === Constants.Invitation.STATUS_CREATED && invitation.is_expired === false
      )
    },
  },
}
