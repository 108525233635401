var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.removalRequestAt
    ? _c("div", { staticClass: "account-removal-bar" }, [
        _c("span", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$t.get("common.user_removal.infobox_header", {
                days: _vm.removalDaysLeft,
              })
            ),
          },
        }),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button text", on: { click: _vm.cancelDelete } },
          [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t.get("common.user_removal.infobox_undo_deletion")
                ) +
                "\n  "
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }