//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createNamespacedHelpers } from "vuex"

const { mapGetters } = createNamespacedHelpers("appointment")

export default {
  data() {
    return {
      reports: [],
      visible: false,
    }
  },
  computed: {
    ...mapGetters(["getContextRecipientOfAppointment"]),
    user() {
      return this.$store.state.user
    },
  },
  mounted() {
    this.getAwaitingReports()
  },
  watch: {
    visible: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$bridge.emit("hideInvitationsPop")
        } else {
          this.$bridge.emit("showInvitationsPop")
        }
      },
    },
  },
  methods: {
    getAwaitingReports() {
      this.$http
        .get("/ajax/appointments/reports/awaiting")
        .then((response) => {
          this.reports = response.data.data
        })
        .catch((error) => {})
    },
    toggleReports() {
      this.visible = !this.visible
    },
    hideReports() {
      this.visible = false
    },
  },
}
