var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "toasts" },
    [
      _c(
        "transition-group",
        {
          attrs: { name: "toast", tag: "div" },
          on: {
            leave: _vm.leave,
            "before-enter": _vm.beforeEnter,
            enter: _vm.enter,
          },
        },
        _vm._l(_vm.toasts, function (toast) {
          return _c(
            "div",
            { key: toast.id, staticClass: "toast", class: toast.type },
            [
              _c("span", { domProps: { innerHTML: _vm._s(toast.message) } }),
              _vm._v(" "),
              toast.action
                ? _c(
                    "a",
                    {
                      staticClass: "action",
                      on: {
                        click: function ($event) {
                          return _vm.callToastAction(toast)
                        },
                      },
                    },
                    [_vm._v(_vm._s(toast.action.text))]
                  )
                : _vm._e(),
              _vm._v(" "),
              toast.type != "loading"
                ? _c("i", {
                    staticClass: "close ict-close",
                    on: {
                      click: function ($event) {
                        return _vm.removeToast(toast)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              toast.type == "loading"
                ? _c(
                    "div",
                    { staticClass: "progress-circle" },
                    [
                      toast.progress != Infinity
                        ? _c("progress-circle", {
                            attrs: {
                              progress: toast.progress,
                              width: "30",
                              height: "30",
                              border: "4",
                              background: "#2b619a",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      toast.progress == Infinity
                        ? _c("div", { staticClass: "preloader" })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }