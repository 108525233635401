//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Velocity from "velocity-animate"
import Modal from "../../plugins/modal/modal"
import { find, forEach } from "lodash"

import VueProgress from "../../plugins/VueProgress.vue"

import { v4 as uuid } from "uuid"

export default {
  data() {
    return {
      toasts: new Array(),
      interval: 3000,
      alert: false,
    }
  },
  bridges: {
    /**
     * Examples:
     *
     * normal:
     * this.$bridge.emit('addToast', 'Test', 'success | error | info');
     *
     * sticky:
     * this.$bridge.emit('addToast', 'Test', 'success | error | info', true);
     *
     * with function button:
     * this.$bridge.emit('addToast', 'Test', 'success | error | info', true | false, {
     * 	text: 'Button text',
     * 	function: () => console.log('test')
     * });
     */
    addToast(message, type, stick, action) {
      // dont show empty toast
      if (message === "") {
        return
      }
      var $this = this
      var toast = {
        message: message,
        type: type,
        action: typeof action != typeof undefined ? action : null,
        id: uuid(),
      }
      $this.toasts.push(toast)
      if (typeof stick == typeof undefined || !stick) {
        setInterval(() => {
          $this.removeToast(toast)
        }, $this.interval)
      }
    },
    addProgressToast(id, message, progress, action) {
      console.log(message, id, progress, action)
      var $this = this
      var toast = {
        message: message,
        type: "loading",
        action: typeof action != typeof undefined ? action : null,
        id: id,
        progress: progress,
      }
      $this.toasts.push(toast)
    },
    updateProgressToast(id, message, progress) {
      let toast = find(this.toasts, (o) => {
        return (o.id = id)
      })
      toast.message = message
      toast.progress = progress
    },
    removeProgressToast(id) {
      let toast = find(this.toasts, (o) => {
        return (o.id = id)
      })
      this.removeToast(toast)
    },
  },
  sockets: {
    errorMessage(message) {
      var $this = this
      var toast = {
        message: message,
        type: "error",
      }
      $this.toasts.push(toast)
      setInterval(() => {
        $this.removeToast(toast)
      }, $this.interval)
    },
  },
  mounted() {
    this.fetchToasts()
    setTimeout(this.sessionExpired, 7200000)
  },
  methods: {
    sessionExpired() {
      if (window.USER_JWT !== "null") {
        Modal.confirm(this)
          .title(this.$t.get("common.session-expired"))
          .content(this.$t.get("common.session-expired-login-question"))
          .positive(this.$t.get("common.login"), () => window.location.reload(true))
          .negative(this.$t.get("common.home-page"), () => (window.location.href = "/"))
          .show()
      } else {
        Modal.confirm(this)
          .title(this.$t.get("common.session-expired"))
          .content(this.$t.get("common.session-expired-refresh"))
          .positive(this.$t.get("common.refresh-page"), () => window.location.reload(true))
          .show()
      }
    },
    connectionError() {
      if (this.alert == false) {
        var toast = {
          message: this.$t.get("common.unable-to-connect"),
          type: "error",
        }
        this.toasts.push(toast)
        setInterval(() => {
          this.removeToast(toast)
        }, this.interval)
        this.alert = true
      }
    },
    fetchToasts() {
      var $this = this
      forEach(window.__ALERTS, (value, key) => {
        let id = "1000"
        forEach(value, (value) => {
          var toast = {
            message: value,
            type: key,
            id: id++,
          }
          $this.toasts.push(toast)
          setInterval(() => {
            $this.removeToast(toast)
          }, $this.interval)
        })
      })
    },
    callToastAction(toast) {
      toast.action.function()
      this.removeToast(toast)
    },
    removeToast(toast) {
      var index = this.toasts.indexOf(toast)
      if (index > -1) this.toasts.splice(index, 1)
    },
    beforeEnter: function (el) {
      el.style.opacity = 0
      el.style.marginBottom = -15
    },
    enter: function (el, done) {
      setTimeout(function () {
        Velocity(el, { opacity: 1, marginBottom: 0 }, { complete: done })
      }, 150)
    },
    leave: function (el, done) {
      setTimeout(function () {
        Velocity(el, { opacity: 0, marginBottom: -15 }, { complete: done })
      }, 150)
    },
  },
  components: {
    "progress-circle": VueProgress,
  },
}
