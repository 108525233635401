var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideNotifications,
          expression: "hideNotifications",
        },
      ],
      staticClass: "icon notification with-list",
      class: { "has-notify": _vm.unreaded > 0, "list-visible": _vm.visible },
    },
    [
      _c(
        "a",
        {
          directives: [{ name: "tooltip", rawName: "v-tooltip" }],
          staticClass: "tooltip-bottom",
          attrs: { href: "#", alt: _vm.$t.get("common.notifications") },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggleNotifications.apply(null, arguments)
            },
          },
        },
        [
          _c("i", { staticClass: "ict-notifications" }),
          _vm._v(" "),
          _c("div", { staticClass: "badge" }, [_vm._v(_vm._s(_vm.unreaded))]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list" },
        [
          _c("div", { staticClass: "header flex" }, [
            _c("div", { staticClass: "lg:w-6/12 columns" }, [
              _vm._v(_vm._s(_vm.$t.get("common.notifications"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "lg:w-6/12 text-right columns" }, [
              _c("a", { attrs: { href: "/notifications" } }, [
                _vm._v(_vm._s(_vm.$t.get("common.show-all-btn"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-scrollbar",
            [
              _vm._l(_vm.notifications, function (notification) {
                return _c(
                  "a",
                  {
                    key: notification.id,
                    staticClass: "item",
                    attrs: { href: "/notifications/" + notification.id },
                  },
                  [
                    notification.data.image
                      ? _c("div", {
                          staticClass: "avatar",
                          style: {
                            backgroundImage:
                              "url(" + notification.data.image + ")",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "info" }, [
                      _c("div", {
                        staticClass: "title",
                        domProps: {
                          innerHTML: _vm._s(notification.data.label),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "date" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$dateTimeFormat.formatDate(
                                notification.created_at
                              )
                            ) +
                            "\n            " +
                            _vm._s(
                              _vm.$dateTimeFormat.formatTime(
                                notification.created_at
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: Object.keys(_vm.notifications).length == 0,
                      expression: "Object.keys(notifications).length == 0",
                    },
                  ],
                  staticClass: "item",
                },
                [
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t.get(
                              "common.you-do-not-have-any-notifications-at-the-moment"
                            )
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "date" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t.get(
                              "common.you-will-find-here-the-list-of-new-all-notifications"
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }