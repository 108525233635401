//
//
//
//
//
//
//
//

import AccountRemoval from "../../mixins/account-removal"

export default {
  mixins: [AccountRemoval],
  methods: {
    cancelDelete() {
      this.$http.post("/ajax/account/removal/cancel").then(() => {
        this.$bridge.emit(
          "addToast",
          this.$t.get("common.user_removal.confirm_cancel_message"),
          "success"
        )
        this.$store.commit("user/clearRemovalRequest")
      })
    },
  },
}
