var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideReports,
          expression: "hideReports",
        },
      ],
      staticClass: "icon reports with-list",
      class: {
        "has-notify": _vm.reports.length > 0,
        "list-visible": _vm.visible,
      },
    },
    [
      _c(
        "a",
        {
          directives: [{ name: "tooltip", rawName: "v-tooltip" }],
          staticClass: "tooltip-bottom",
          attrs: { alt: _vm.$t.get("common.reports-to-complete") },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggleReports.apply(null, arguments)
            },
          },
        },
        [
          _c("i", { staticClass: "ict-report" }),
          _vm._v(" "),
          _c("div", { staticClass: "badge" }, [
            _vm._v(_vm._s(_vm.reports.length)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list" },
        [
          _c("div", { staticClass: "header" }, [
            _vm._v(_vm._s(_vm.$t.get("common.reports-to-complete"))),
          ]),
          _vm._v(" "),
          _c(
            "v-scrollbar",
            [
              _vm._l(_vm.reports, function (appointment) {
                return _c(
                  "a",
                  {
                    key: appointment.appointment_id,
                    staticClass: "item",
                    attrs: {
                      href: "/appointment/" + appointment.appointment_id,
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "avatar",
                      style: {
                        backgroundImage:
                          "url(" +
                          _vm.getContextRecipientOfAppointment(
                            appointment,
                            _vm.user.user_id
                          ).avatar_url +
                          ")",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "info" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t.get("common.report-for")) +
                            " " +
                            _vm._s(appointment.requester.display_name) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "date" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              appointment.term
                                ? _vm.$dateTimeFormat.formatDate(
                                    appointment.term.end_date
                                  ) +
                                    " " +
                                    _vm.$dateTimeFormat.formatTime(
                                      appointment.term.end_date
                                    )
                                : "-"
                            ) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.reports.length === 0,
                      expression: "reports.length === 0",
                    },
                  ],
                  staticClass: "item",
                },
                [
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t.get(
                              "common.you-do-not-have-any-reports-to-complete"
                            )
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "date" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t.get(
                              "common.you-will-find-here-the-list-of-all-reports-pending-completion"
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }